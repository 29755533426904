<template>
    <div>
        <section class="relative leading-7 text-gray-900 bg-white border-solid pb-8">
            <div class="box-border mx-auto border-solid lg:pl-8 max-w-7xl">
                <div class="flex flex-col items-center leading-7 text-gray-900 border-0 border-gray-200 lg:flex-row">
                    <div
                        class="box-border flex flex-col justify-center w-full h-full p-8 text-gray-900 border-solid lg:w-1/2 md:p-16 lg:p-0 lg:pl-10 lg:pr-20">
                        <h2 class="m-0 text-3xl font-medium leading-tight tracking-tight text-left text-black sm:text-4xl md:text-4xl">
                            How to use tokengen</h2>

                        <p class="mt-2 text-xl text-left border-0 border-gray-200 sm:text-xl">To make your own
                            token</p>
                        <div
                            class="grid gap-4 mt-8 leading-7 border-0 border-gray-200 sm:mt-10 sm:gap-6 lg:mt-12 lg:gap-8">
                            <div class="box-border flex items-start text-gray-900 border-solid">
                                <div
                                    class="flex items-center justify-center w-12 h-12 leading-7 bg-blue-600 border-0 border-gray-200 rounded-full">
                                    <p class="box-border m-0 text-xl text-white border-solid">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none"
                                             viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                  d="M8 9l4-4 4 4m0 6l-4 4-4-4"/>
                                        </svg>
                                    </p>
                                </div>
                                <div class="flex-1 ml-6 leading-7 border-0 border-gray-200">
                                    <h3 class="box-border m-0 text-lg font-semibold leading-tight tracking-tight text-black border-solid sm:text-xl md:text-2xl">
                                        Selecting your token</h3>
                                    <p class="box-border mt-2 text-base leading-normal text-gray-900 border-solid">
                                        Select
                                        the token that suites your project here:
                                        <router-link :to="{name:'create'}" class="underline">Create token.</router-link>
                                        <br/>
                                        Prices ranges depending on what kind of contract you choose.
                                    </p>
                                </div>
                            </div>
                            <div class="box-border flex items-start text-gray-900 border-solid">
                                <div
                                    class="flex items-center justify-center w-12 h-12 leading-7 bg-blue-600 border-0 border-gray-200 rounded-full">
                                    <p class="box-border m-0 text-xl text-white border-solid">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none"
                                             viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                  d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>
                                        </svg>
                                    </p>
                                </div>
                                <div class="flex-1 ml-6 leading-7 border-0 border-gray-200">
                                    <h3 class="box-border m-0 text-lg font-semibold leading-tight tracking-tight text-black border-solid sm:text-xl md:text-2xl">
                                        Fill out details</h3>
                                    <p class="box-border mt-2 text-base leading-normal text-gray-900 border-solid">Fill
                                        out
                                        the details that we require in the next steps, such as Token name, symbol and
                                        supply.</p>

                                </div>
                            </div>

                            <div class="box-border flex items-start text-gray-900 border-solid">
                                <div
                                    class="flex items-center justify-center w-12 h-12 leading-7 bg-blue-600 border-0 border-gray-200 rounded-full">
                                    <p class="box-border m-0 text-xl text-white border-solid">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none"
                                             viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                  d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"/>
                                        </svg>
                                    </p>
                                </div>
                                <div class="flex-1 ml-6 leading-7 border-0 border-gray-200">
                                    <h3 class="box-border m-0 text-lg font-semibold leading-tight tracking-tight text-black border-solid sm:text-xl md:text-2xl">
                                        Verify and deploy</h3>
                                    <p class="box-border mt-2 text-base leading-normal text-gray-900 border-solid">Check
                                        your contract before deployment. Check if you've misspelled anything and you're
                                        happy with your contract choice.</p>

                                </div>
                            </div>

                        </div>
                    </div>
                    <div
                        class="w-full h-full overflow-hidden leading-7 text-gray-900 border-0 border-gray-200 lg:w-1/2 rounded-lg shadow-lg">
                        <img src="https://i.imgur.com/EPZaH35.png" class="object-cover w-full h-full">
                    </div>
                </div>
            </div>
        </section>

        <!-- Section 2 -->
        <middle-banner/>

        <!-- Section 4 -->
        <section class="py-20 bg-white 0">
            <div class="flex flex-col px-8 mx-auto space-y-12 max-w-7xl xl:px-12  pb-10">
                <div class="relative">
                    <div class="rounded-md bg-blue-50 p-4">
                        <div class="flex">
                            <div class="flex-shrink-0 ">

                                <svg class="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 20 20"
                                     fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd"
                                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                          clip-rule="evenodd"/>
                                </svg>
                            </div>
                            <div class="ml-3 flex-1 md:flex md:justify-between">
                                <p class="text-sm text-blue-700">
                                    Tokengen only supports <a href="https://metamask.io" class="underline">Metamask</a>
                                    at
                                    the moment. Please make sure you have metamask installed. <br/>
                                    <i>If you cannot connect wallet, try to refresh your browser</i>.
                                </p>
                                <p class="mt-3 text-sm md:mt-2 md:ml-6">
                                    <router-link :to="{name:'guides.metamask'}"
                                                 class="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600">
                                        Metamask guide
                                        <span aria-hidden="true">&rarr;</span>
                                    </router-link>
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- End of info-->
                    <h2 class="w-full text-3xl font-bold text-center sm:text-4xl md:text-5xl pt-5">How to deploy a token
                        with Tokengen</h2>
                    <p class="w-full py-8 mx-auto -mt-2 text-lg text-center text-gray-700 intro sm:max-w-3xl">Before
                        getting
                        started, please click the <img class=" inline-block" src="https://i.imgur.com/C18Yya0.png"/> on
                        top
                        to connect your wallet. If you have not yet setup a wallet with Metamask, please refer to
                        <router-link :to="{name:'guides.metamask'}" class="underline">this guide</router-link>
                        before continuing.
                    </p>

                </div>
                <div class="flex flex-col mb-8 animated fadeIn sm:flex-row">
                    <div class="flex items-center mb-8 sm:w-1/2 md:w-5/12 sm:order-last">
                        <img class="rounded-lg shadow-xl" src="https://i.imgur.com/iLf6x03.png" alt="">
                    </div>
                    <div class="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pr-16">
                        <p class="mb-2 text-sm font-semibold leading-none text-left text-indigo-600 uppercase">
                            SELECTION</p>
                        <h3 class="mt-2 text-2xl sm:text-left md:text-4xl">Select the contract</h3>
                        <p class="mt-5 text-lg text-gray-700 text md:text-left">The first step before deploying your
                            contract, is to select <i>what</i> kind of contract you need for your project. Take your
                            time
                            and think about this. Will you need to
                            <router-link :to="{name:'guides.burn'}" class="underline">burn</router-link>
                            tokens to remove tokens from your supply? Or maybe
                            <router-link :to="{name:'guides.mint'}" class="underline">mint</router-link>
                            tokens to add even more tokens into circulation?
                        </p>
                    </div>
                </div>
                <div class="flex flex-col mb-8 animated fadeIn sm:flex-row">
                    <div class="flex items-center mb-8 sm:w-1/2 md:w-5/12">
                        <img class="rounded-lg shadow-xl" src="https://i.imgur.com/nxlImvN.png" alt="">
                    </div>
                    <div class="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pl-16">
                        <p class="mb-2 text-sm font-semibold leading-none text-left text-indigo-600 uppercase">
                            DETAILS</p>
                        <h3 class="mt-2 text-2xl sm:text-left md:text-4xl">Fill out the details</h3>
                        <p class="mt-5 text-lg text-gray-700 text md:text-left">In step 2 you're asked to fill out some
                            information about your tokens. This includes <b>Token name, Symbol, Initial supply, max
                                supply
                                and decimals.</b>
                            After filling out these steps you're headed to the final step of your contract deployment.
                        </p>
                    </div>
                </div>
                <div class="flex flex-col mb-8 animated fadeIn sm:flex-row">
                    <div class="flex items-center mb-8 sm:w-1/2 md:w-5/12 sm:order-last">
                        <img class="rounded-lg shadow-xl" src="https://i.imgur.com/J6zStRx.png" alt="">
                    </div>
                    <div class="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pr-16">
                        <p class="mb-2 text-sm font-semibold leading-none text-left text-indigo-600 uppercase">
                            VERIFICATION
                            AND DEPLOYMENT</p>
                        <h3 class="mt-2 text-2xl sm:text-left md:text-3xl">Verify your information and deploy token</h3>
                        <p class="mt-5 text-lg text-gray-700 text md:text-left">This is the last step before deploying
                            your
                            contract on the Binance Smart Chain Network. Go through it all, check if you're missing
                            anything
                            and if everything seems okay. Once you're happy, check the comission- and gas fee is
                            correct.
                            You can always go back in the steps and select another contract, if you're not happy with
                            your
                            current one. Once you're happy, Confirm to our <a href="#">Terms of Use</a> and confirm what
                            network you're deploying on. <br/>
                            Press the <img src="https://i.imgur.com/5fFreBJ.png" class="inline-block" /> to deploy your
                            contract. Confirm the transaction in metamask and you're done.
                        </p>
                    </div>
                </div>
                <div class="flex flex-col mb-8 animated fadeIn sm:flex-row">
                    <div class="flex items-center mb-8 sm:w-1/2 md:w-5/12">
                        <img class="rounded-lg shadow-xl" src="https://i.imgur.com/mbIaEua.png" alt="">
                    </div>
                    <div class="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pl-16">
                        <p class="mb-2 text-sm font-semibold leading-none text-left text-indigo-600 uppercase">
                            CONCLUSION</p>
                        <h3 class="mt-2 text-2xl sm:text-left md:text-4xl">Contract deployed</h3>
                        <p class="mt-5 text-lg text-gray-700 text md:text-left">Congratulations on deploying your
                            contract
                            to the Binance Smart Chain! You can now click the button "Add to metamask" to add your
                            contract
                            to your metamask wallet. All tokens are automatically sent to your wallet. If you want to
                            list
                            your toke on exchanges, you can <br/>
                            <router-link :to="{name:'guides.liquidity'}" class="underline">Check out our guide on how to list your token on Pancakeswap</router-link>
                        </p>
                    </div>
                </div>

            </div>
        </section>
    </div>
</template>

<script>
import MiddleBanner from './MiddleBanner';

export default {
    name: 'tokengen',
    components: { MiddleBanner },
};
</script>

<style scoped>

</style>
